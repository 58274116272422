import HttpFactory from "../factory";
import type {ColumnFilter} from "@tanstack/vue-table";

class BusinessCaseModule extends HttpFactory {
  async listBusinessCases(pageNumber: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("api/business-case/list", "post", {
      query: {
        page: pageNumber,
        page_size: pageSize,
        sort
      }
    },
    {filters: filters});
  }

  async rejectedListBusinessCases(pageNumber: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("api/business-case/rejected/list", "post", {
      query: {
        page: pageNumber,
        page_size: pageSize,
        sort
      }
    },
    {filters: filters});
  }

  async businessCaseDetail(id: string) {
    return await this.call("api/business-case/detail/{id}", "get", {
      path: {id}
    });
  }

  async updateBusinessCase(id: string, data: BusinessCase) {
    return await this.call("api/business-case/update-detail/{id}", "patch", {
      path: {id}
    },
    {
      ...data
    });
  }

  async payBusinessCaseCharge(id: string, isPaid: boolean, isOnStore: boolean, cashDeskId: string) {
    return await this.call("api/business-case/pay-charge/{id}", "post", {
      path: {id}
    },
    {
      paid: isPaid,
      on_store: isOnStore,
      cash_desk_id: cashDeskId,
    });
  }

  async createBusinessCaseCharge(id: string, value: number, description: string = "") {
    return await this.call("api/business-case/create-charge/{id}", "post", {
      path: {id}
    },
    {
      value,
      description
    });
  }

  async loadQuality(id: string) {
    return await this.call("api/business-case/load-quality/{id}", "get", {path: {id}});
  }

  async businessCasesStatesList() {
    return await this.call("api/business-case/states/list", "get");
  }

  async businessCaseHistory(id: string, pageNumber: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("api/business-case/history/grid/{id}", "post", {
      path: {id},
      query: {
        page: pageNumber,
        page_size: pageSize,
        sort
      }
    },
    {filters: filters});
  }

  async deleteBusinessCases(ids: string[], cancelReason: string, customCancelReason: string) {
    return await this.call("api/business-case/delete", "delete", {
      path: {}
    },
    {ids: ids, cancel_reason: cancelReason, custom_cancel_reason: customCancelReason}
    );
  }

  async updateBusinessCasePersonalData(id: string, customer_info: APIRequestBody<"api/business-case/update-business-case-personal-data/{id}", "patch">) {
    return await this.call("api/business-case/update-business-case-personal-data/{id}", "patch", {
      path: {id}
    },
    {...customer_info}
    );
  }

  async getCustomerDevices() {
    return await this.call("api/business-case/customer/devices", "get");
  }

  async listBusinessCaseIssues(page: number, pageSize: number, filter?: string[]) {
    return await this.call("api/business-case/business-case-issues", "get", {
      query: {
        state: filter,
        page,
        page_size: pageSize
      }
    });
  }

  async getProblemDetail(problemId: string) {
    return await this.call("api/business-case/business-case-detail/{problemId}", "get", {
      path: {problemId}
    });
  }

  async listBusinessCaseIssueStates() {
    return await this.call("api/business-case/business-case-issue-states", "get");
  }

  async getBusinessCaseIssueStates(businessCaseIssueId: string) {
    return await this.call("api/business-case/business-case/issue-states/{businessCaseIssueId}", "get", {
      path: {businessCaseIssueId}
    });
  }


  async getUserBusinessCaseDevices(page: number, pageSize: number) {
    return await this.call("api/business-case/user-business-case-devices", "get", {
      query: {
        page,
        page_size: pageSize,
      },
    });
  }

  async getUserPastBusinessCaseDevices() {
    return await this.call("api/business-case/user-past-business-case-devices", "get");
  }

  async hcTestUpdateStatus(ids: string[], isForContract: boolean) {
    return await this.call("api/business-case/hc-test/update-status", "post", {}, {
      ids,
      is_for_contract: isForContract
    });
  }

  async hcTestSetSent(ids: string[], sentDate: string) {
    return await this.call("api/business-case/hc-test/set-sent", "post", {}, {ids, sent_date: sentDate});
  }

  async hcTestSetDelivered(ids: string[], sentDate: string, deliveredDate: string, isDelivered: boolean) {
    return await this.call("api/business-case/hc-test/set-delivered", "post", {}, {
      ids,
      sent_date: sentDate,
      delivered_date: deliveredDate,
      is_delivered: isDelivered
    });
  }

  async hcTestTerminate(
    ids: string[],
    terminationType: string,
    terminationReason: string,
    terminationDate: string | null,
    returnGoodsDate: string | null,
    isGoodsReturned: boolean,
    goodsWear: string | null,
    paymentValue: number,
    paymentDate: string | null
  ) {
    return await this.call("api/business-case/hc-test/terminate", "post", {}, {
      ids,
      termination_type: terminationType,
      termination_reason: terminationReason,
      termination_date: terminationDate,
      return_goods_date: returnGoodsDate,
      is_goods_returned: isGoodsReturned,
      goods_wear: goodsWear,
      payment_value: paymentValue,
      payment_date: paymentDate,
    });
  }

  async hcApplicationStatesList() {
    return await this.call("api/business-case/homecredit-states/application", "get");
  }

  async hcContractStatesList() {
    return await this.call("api/business-case/homecredit-states/contract", "get");
  }

  async listBusinessCaseIssueTypes(businesscaseId: string) {
    return await this.call("api/business-case/business-case/issue-types/{businesscaseId}", "get", {
      path: {businesscaseId}
    });
  }

  async getDeviceDetails(problemId: string) {
    return await this.call("api/business-case/device-details/{problemId}", "get", {
      path: {problemId}
    });
  }

  async listBusinessCaseIssueDamages() {
    return await this.call("api/business-case/business-case-issue-damages", "get");
  }

  async getInfoForReturnPages(businessCaseId: string) {
    return await this.call("api/business-case/info-for-return-pages/{businessCaseId}", "get", {
      path: {businessCaseId}
    });
  }

  async prolongExpiration(businessCaseId: string) {
    return await this.call("api/business-case/prolong/{businessCaseId}", "patch", {
      path: {businessCaseId}
    });
  }

  async listBusinessCaseInvoices(pageNumber: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("api/business-case/invoices", "post", {
      query: {
        page: pageNumber,
        page_size: pageSize,
        sort
      }
    },
    {filters: filters});
  }

  async downloadInvoiceISDOCX(invoiceId: string) {
    return await this.call("/api/business-case/invoice/{invoiceId}/isdocx", "get", {
      path: {invoiceId}
    }, {}, {responseType: "blob"});
  }
}


export default BusinessCaseModule;
